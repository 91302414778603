@charset "UTF-8";

body,
html {
  height: 100%
}

body {
  font-family: '微软雅黑';
  background: #fff
}

.login-top {
  display: table;
  width: 100%;
  padding-left: 14%;
  margin: 0 auto
}

.login-footer,
.login-top {
  position: relative;
  height: 19%
}

.login-top .login-top-logo {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  font-size: 40px;
  font-weight: bold;
}
.login-top .login-top-logo .logo{
  height: 60px;  
}

.login-content {
  width: 100%;
  height: 62%
}

.login-form-title {
  font-size: 20px;
  line-height: 38px;
  font-weight: 700
}

.login-content .login-form {
  position: absolute;
  top: 50%;
  right: 10%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  width: 27%;
  min-width: 300px;
  max-width: 530px;
  padding: 2%;
  color: #fff;
  border-radius: 2%;
  background: rgba(255, 255, 255, .8);
  -webkit-box-shadow: 0 2px 50px 0 rgba(0, 94, 170, .8);
  box-shadow: 0 2px 50px 0 rgba(0, 94, 170, .8)
}

.login-form .form-control {
  width: 100%;
  border: 0;
  background: #f8f8f8;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
  height: 40px
}

.login-form .error {
  position: absolute;
  right: 40px;
  left: 40px;
  margin-top: -5px
}

.login-form .error-tips {
  display: none;
  text-align: center;
  line-height: 1;
  font-size: 12px;
  opacity: 1;
  color: #f60
}

.login-form .show {
  opacity: 1
}

.login-form .kaptchaLabel {
  display: block
}

.login-form #kaptcha {
  display: inline-block;
  width: 65%;
  border-top-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 5px
}

.login-form #kaptcha::-ms-clear {
  display: none
}

.login-form .fa-check-circle-o,
.login-form .fa-times-circle-o {
  position: absolute;
  font-size: 22px;
  display: inline-block;
  margin-top: 6px;
  margin-left: -32px;
  -webkit-transition: color .5s ease;
  transition: color .5s ease
}

.login-form .fa-check-circle-o {
  color: #8ece4d
}

.login-form .fa-times-circle-o {
  color: #fb4242
}

.login-form .checkbox,
.repwd {
  line-height: 16px;
  font-size: 14px
}

.login-form .checkbox {
  color: #9b9b9b;
  height: 16px;
  margin-top: -5px
}

.login-form input[type=checkbox] {
  position: relative;
  width: 20px
}

#remember {
  margin-right: 3px;
  margin-left: -15px;
  line-height: 16px
}

#remember img {
  width: 100%;
  display: none;
  margin-top: 0
}

#noRem,
#remember {
  display: inline-block;
  width: 16px;
  height: 16px
}

.repwd {
  margin-top: 0
}

.login-form #kaptcha+img {
  position: absolute;
  right: 40px;
  margin-top: -34px
}

.js-kaptcha {
  width: 33%;
  height: 40px
}

.login-form .login-btn {
  font-size: 20px;
  border-radius: 5px;
  width: 100%;
  margin-top: 20px;
  border: 0;
  background: #007aff;
  height: 50px;
  line-height: 50px
}

.login-form .login-btn.disabled {
  background: #666;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed
}

.login-footer .copyright {
  position: absolute;
  top: 60%;
  width: 100%;
  text-align: center;
  color: #666;
  font-size: 16px
}


.login-icon-eye-container {
  position: relative;
}

.login-icon-eye {
  position: absolute;
  right: 15px;
  top: 12px;

  height: 20px;
  width: 20px;
  background-size: contain;
  right: 20px;
  position: absolute;
  cursor: pointer;
  background-image: url('data:image/svg+xml,%3Csvg class="icon" viewBox="0 0 1024 1024" xmlns="http%3A//www.w3.org/2000/svg" width="72" height="72"%3E%3Cpath d="M512.022 546.418c-184.178 0-368.07-55.922-499.114-167.538a37.575 37.575 0 0 1-3.5-53.163 37.945 37.945 0 0 1 53.42-3.47c231.082 198.827 668.302 198.827 898.389 0a37.945 37.945 0 0 1 53.419 3.47c13.767 15.645 12.23 39.453-3.5 53.191-131.043 111.588-314.936 167.51-499.114 167.51zM375.262 710.94a30.834 30.834 0 0 1-6.742 0c-10.325-1.422-19.627-7.11-25.543-15.644-5.945-8.562-7.965-19.228-5.632-29.355l24.946-146.63a37.973 37.973 0 0 1 43.918-30.806 37.831 37.831 0 0 1 30.947 43.747l-24.945 146.603a37.376 37.376 0 0 1-36.95 32.085zm561.01-74.58a36.925 36.925 0 0 1-26.454-11.18L786.796 500.935a37.177 37.177 0 0 1 16.042-64.455 37.547 37.547 0 0 1 37.092 12.26l123.05 124.245c14.592 14.563 14.592 38.144 0 52.707a39.078 39.078 0 0 1-26.737 10.667zm-848.499 0a38.997 38.997 0 0 1-24.945-10.668 37.177 37.177 0 0 1 0-52.707L185.85 448.74a37.518 37.518 0 0 1 67.47 14.136 37.205 37.205 0 0 1-15.815 38.06L114.483 625.18a37.006 37.006 0 0 1-26.71 11.18zm562.745 74.58a37.376 37.376 0 0 1-36.693-30.833l-24.946-146.631a37.177 37.177 0 0 1 9.757-38.827 37.547 37.547 0 0 1 39.424-7.68c13.54 5.319 22.755 17.92 23.694 32.341L686.7 665.941a36.83 36.83 0 0 1-6.03 27.904 37.24 37.24 0 0 1-24.178 15.36 30.805 30.805 0 0 1-5.974 1.707z" fill="%238f8f8f"/%3E%3C/svg%3E');
}
.login-icon-eye.open {
    background-image: url('data:image/svg+xml,%3Csvg class="icon" viewBox="0 0 1024 1024" xmlns="http%3A//www.w3.org/2000/svg" width="72" height="72"%3E%3Cpath d="M1018.738 480.711s-4.267-12.8-8.534-17.067l-21.333-42.666c-21.333-34.134-51.2-76.8-93.867-123.734-81.066-89.6-209.066-183.466-384-183.466s-302.933 93.866-384 183.466c-42.666 46.934-72.533 89.6-93.866 123.734-8.534 17.066-17.067 29.866-21.334 42.666-4.266 4.267-8.533 17.067-8.533 17.067-4.267 12.8-4.267 25.6 0 34.133 0 0 4.267 12.8 8.533 17.067l21.334 42.667c21.333 34.133 51.2 76.8 93.866 123.733 81.067 89.6 209.067 183.467 384 183.467s302.934-93.867 384-183.467c42.667-46.933 72.534-89.6 93.867-123.733 8.533-17.067 17.067-29.867 21.333-42.667 4.267-4.267 8.534-17.067 8.534-17.067 4.266-12.8 4.266-21.333 0-34.133zm-102.4 46.933c-17.067 29.867-46.934 72.534-81.067 110.934-76.8 81.066-183.467 157.866-324.267 157.866s-247.466-76.8-324.266-157.866c-38.4-38.4-64-81.067-81.067-110.934-8.533-12.8-12.8-21.333-17.067-29.866 4.267-8.534 8.534-17.067 17.067-29.867 21.333-29.867 46.933-72.533 81.067-110.933 76.8-81.067 183.466-157.867 324.266-157.867s247.467 76.8 324.267 157.867c38.4 38.4 64 81.066 81.067 110.933 8.533 12.8 12.8 21.333 17.066 29.867-4.266 8.533-12.8 17.066-17.066 29.866z" fill="%238f8f8f"/%3E%3Cpath d="M511.004 344.178c-76.8 0-145.066 68.266-145.066 153.6 0 85.333 68.266 153.6 145.066 153.6s145.067-68.267 145.067-153.6c0-85.334-68.267-153.6-145.067-153.6zm-230.4 153.6c0-128 102.4-238.934 230.4-238.934s230.4 110.934 230.4 238.934-102.4 238.933-230.4 238.933-230.4-110.933-230.4-238.933z" fill="%238f8f8f"/%3E%3C/svg%3E');
}


/*# sourceMappingURL=login.css.map */